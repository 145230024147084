import { LandingItem } from "@/interfaces/landingItem";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./landing.template.vue";

@Component({
  mixins: [Template]
})
export default class LandingComponent extends Vue {
  @Prop({ default: () => [] }) public items!: LandingItem[];
  @Prop({ default: false }) public emitNamedRoute!: boolean;

  public showItems: LandingItem[] = [];

  protected mounted() {
    this.showItems = this.items.filter(
      item =>
        (item.visibleCondition && item.visibleCondition()) ||
        !item.visibleCondition
    );
  }

  protected goTo(namedRoute: string): void {
    if (!this.emitNamedRoute) {
      this.$router.push({ name: namedRoute });
    } else {
      this.$emit("namedRoute", namedRoute);
    }
  }
}
