import landing from "@/components/sharedComponents/landing/landing.component";
import { policyList } from "@/enums/permissions";
import { LandingItem } from "@/interfaces/landingItem";
import { store } from "@/internal";
import { BooleanCheck, PageNavAction } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./RetailOptions.template.vue";

@Component({
  mixins: [Template],
  components: {
    landing
  }
})
export default class RetailOptionsComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public landingOptions: LandingItem[] = [];
  public goTo(route: string) {
    this.$router.push(route);
  }

  public mounted() {
    this.setPageNav({
      title: "retail_options"
    });
  }

  protected created() {
    this.landingOptions = [
      {
        namedRoute: "discount-manager",
        translationString: "discount_manager.title"
      },
      {
        namedRoute: "loyalty-programs",
        translationString: "loyalty_programs.title"
      },
      {
        namedRoute: "retail-settings",
        translationString: "retail_settings.title",
        visibleCondition: () => {
          return this.hasPermission(policyList.viewRetailSettings);
        }
      },
      {
        namedRoute: "doctors",
        translationString: "doctors.title",
        visibleCondition: () => {
          const retail = store.getters["AuthModule/currentRetailSettings"];
          return (
            retail.doctor_tracking_enabled === 1 &&
            this.hasPermission(policyList.viewDoctors)
          );
        }
      }
    ];
  }
}
